import { userProps } from "../interface";

export function getStoredUser() {
  const storedUser = localStorage.getItem("user");
  // console.log(storedUser, "Stored User Data");
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user: userProps) {
  localStorage.setItem("user", JSON.stringify(user));
}

// STUB: save login token to local storage
export function setLoginToken(token: string) {
  localStorage.setItem("token", JSON.stringify(token));
}

// STUB: get login token from local storage
export function getLoginToken() {
  const storedToken = localStorage.getItem("token");
  // console.log(storedToken, "Stored User Token");
  return storedToken ? JSON.parse(storedToken) : null;
}
export function getToken() {
  return localStorage.getItem("token");
}

export function getStoredSettings() {
  const storedSettings = localStorage.getItem("settings");
  return storedSettings ? JSON.parse(storedSettings) : null;
}
