import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export function CircleLoading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  );
}
