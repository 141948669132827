import { useQuery } from "@tanstack/react-query";
// import { queryKeys } from "../../react-query/constants";
import { useContext } from "react";
import { AuthContext } from "../../context";
import { getLoginToken, setLoginToken, setStoredUser } from "../../storage";
import { axiosInstance } from "../../axios-Instance";
import { isAuthenticated } from "../../utils";
import { auth } from "../../Firebase";
import { signOut } from "firebase/auth";
import { queryClient } from "../../react-query";
import { useNavigate } from "react-router-dom";

const userProfile = async () => {
  const data = await axiosInstance({
    url: "users/auth/me",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLoginToken()}`,
    },
  });
  return data?.data;
};

export function useAuthenticatedUser() {
  const authCtx = useContext(AuthContext);
  const fallback = undefined;
  const { data = fallback } = useQuery({
    enabled: isAuthenticated(),
    // queryKey: [queryKeys.user],
    queryFn: () => userProfile(),
    onSuccess: (data: any) => {
      authCtx.updateUser(data);
      setStoredUser(data);
    },
    onError: (_error: any) => {
      // authCtx.logout();
    },
  });
  return data;
}

const totalLogout = async () => {
  try {
    await signOut(auth);

    queryClient.removeQueries();
    clearLocalStorage();
  } catch (error) {
    console.error("Error during total logout:", error);
  }
};

function clearLocalStorage() {
  setStoredUser({
    id: "",
    uid: "",
    _id: "",
    gender: "",
    lastName: "",
    firstName: "",
    email: "",
    token: "",
    schoolName: "",
    department: "",
    matricNumber: "",
    imageUrl: null as File | null,
    state: "",
    yearOfEntry: "",
  });
  setLoginToken("");
  localStorage.clear();
}

export function useFullLogout() {
  const navigate = useNavigate();

  const fullLogout = async () => {
    await totalLogout();
    navigate("/login");
  };

  return fullLogout;
}
