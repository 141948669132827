import { createContext, useEffect, useState } from "react";
// import { queryKeys } from "../react-query/constants";
import { getLoginToken, getStoredUser, setStoredUser } from "../storage";
import { useFullLogout } from "./hooks";
import { useQueryClient } from "@tanstack/react-query";
import { ChildProps, userProps } from "../interface";
import { getDecodedJWT, isAuthenticated } from "../utils";
import { getAuth, signOut } from "firebase/auth";
import app from "../Firebase";

export const AuthContext = createContext({
  user: undefined as userProps | undefined,
  token: undefined as string | undefined,
  isAuthenticated: false,
  authenticate: (_token: string) => {},
  // logout: () => {},
  // totalLogout: async () => {},
  updateUser: (_data: userProps) => {},
});

function AuthContextProvider({ children }: ChildProps) {
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<userProps | undefined>(undefined);
  const userDetails = getStoredUser();
  // const queryClient = useQueryClient();
  // const fullLogout = useFullLogout();

  useEffect(() => {
    if (!user) {
      // fullLogout();
    }
  }, []);

  useEffect(() => {
    const data = getLoginToken();
    if (data) {
      setAuthToken(data);
    }
  }, []);

  // useEffect(() => {
  //   const data = getStoredUser();
  //   if (data && data) {
  //     setUser(data);
  //   }
  // }, []);

  useEffect(() => {
    if (userDetails && userDetails) {
      setUser(userDetails);
    }
  }, []);

  function updateUser(data: userProps) {
    setUser(data);
  }

  function authenticate(data: string) {
    setAuthToken(data);
    const decoded = getDecodedJWT();
    const userPropsObj: userProps = {
      uid: decoded?.uid || "",
      _id: decoded?._id || "",
      firstName: decoded?.firstName || "",
      lastName: decoded?.lastName || "",
      token: decoded?.token || "",
      email: decoded?.email || "",
      id: decoded?.id || "",
      gender: decoded?.gender || "",
      role: decoded?.role || "",
      schoolName: "",
      department: "",
      matricNumber: "",
      imageUrl: null as File | null,
      state: "",
      yearOfEntry: "",
    };
    setUser(userPropsObj);
    setStoredUser(userPropsObj);
  }

  const value = {
    user: user,
    token: authToken,
    isAuthenticated: !!authToken,
    authenticate: authenticate,
    // logout: logout,
    updateUser: updateUser,
    // totalLogout: totalLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
