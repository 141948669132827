import { Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo } from "react";
import { AuthContext } from "../context";
import { CircleLoading } from "../components/loading/circle-loading";
import { isAuthenticated } from "../utils";
import { getStoredUser } from "../storage";
import { useFullLogout } from "../context/hooks";

interface AuthGuardProps {
  children: React.ReactNode;
  allowedRoles?: string[];
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children, allowedRoles }) => {
  const user = getStoredUser();
  const navigate = useNavigate();
  const fullLogout = useFullLogout();

  // console.log(user, "checking from AuthGuard");

  const loggedInUser = useMemo(() => {
    return user;
  }, [user]);

  useEffect(() => {
    if (!user) {
      (async () => {
        await fullLogout();
        navigate("/login", { replace: true });
      })();
    }
  }, [user, navigate, fullLogout]);

  if (!user) {
    return <CircleLoading />;
  }

  return <>{children}</>;
};

export default AuthGuard;
